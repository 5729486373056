import { Spinner } from "@chakra-ui/react";
import { apiGetErrorDetail } from "@raiden/library/helpers/api";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { useIntl } from "react-intl";
import Splash from "../../Splash";

/**
 * @template [T=any]
 * @param {object} props
 * @param {import("swr").SWRResponse<T>} props.swrResponse the SWR response
 * @param {boolean} [props.keepDataOnRevalidation] if true, the children will be rendered even if the data is being revalidated
 * @param {boolean} [props.shouldRenderWithEmptyData] if true, the children will be rendered even if the data is empty
 * @param {import("react").ReactElement} [props.noResult] the element to render if the data is empty
 * @param {boolean} [props.renderNoResultSplash] if true, the no result splash will be rendered
 * @param {import("react").ReactElement} [props.loading] the element to render if the data is loading
 * @param {(children: import("react").ReactNode) => import("react").ReactElement} [props.wrapper] the wrapper that will be used to render any interal elements
 * @param {(response: Exclude<T & {}, undefined>) => import("react").ReactNode} props.children
 */
function SWRHandler({
  swrResponse,
  keepDataOnRevalidation = false,
  shouldRenderWithEmptyData = false,
  renderNoResultSplash = true,
  noResult,
  loading,
  wrapper = (children) => <>{children}</>,
  children,
}) {
  const intl = useIntl();

  if (
    swrResponse.data !== undefined &&
    swrResponse.data !== null &&
    (keepDataOnRevalidation || swrResponse.isValidating === false)
  ) {
    const data = swrResponse.data["data"];
    if (
      Array.isArray(data) &&
      data.length === 0 &&
      !shouldRenderWithEmptyData
    ) {
      return renderNoResultSplash
        ? noResult ??
            wrapper(
              <Splash
                src={generateFrontPath({
                  id: "internal-assets",
                  parameters: { filePath: "illustrations/empty.svg" },
                  includeBasePath: true,
                })}
                title={intl.formatMessage({ defaultMessage: "Aucun résultat" })}
              />,
            )
        : null;
    }
    return <>{children(swrResponse.data)}</>;
  }

  if (!swrResponse.isValidating && swrResponse.error) {
    return wrapper(
      <Splash
        src={generateFrontPath({
          id: "internal-assets",
          parameters: { filePath: "illustrations/error.svg" },
          includeBasePath: true,
        })}
        title={intl.formatMessage({
          defaultMessage: "Une erreur est survenue",
        })}
        description={apiGetErrorDetail({ error: swrResponse.error })}
      />,
    );
  }

  if (swrResponse.isValidating) {
    return loading ?? wrapper(<Spinner />);
  }

  return null;
}

export default SWRHandler;
