import { useCallback } from "react";
import useSWR from "swr";
import { SWR_CONFIG_FOR_STATIC_DATA } from "../constants/swr/swrConfigForStaticData";
import useApiFetcher from "./useApiFetcher";

/**
 * @template T,[M=object]
 * @typedef {{swrResponse: import("swr").SWRResponse<import("@raiden/library/types/Api/ApiResponse").ApiResponse<T, M>>}} UseApi
 **/

/**
 * @template T,[M=object]
 * @param {string | null} url
 * @param {object} [options]
 * @param {"GET" | "POST" | "PUT" | "DELETE"} [options.method]
 * @param {Partial<import("swr/dist/types").PublicConfiguration>} [options.swrConfig]
 * @returns {UseApi<T,M>}
 **/

export function useApi(url, options = { method: "GET" }) {
  const apiFetcher = useApiFetcher();

  const fetcher = useCallback(
    (url) => {
      return apiFetcher(url, { method: options.method });
    },
    [apiFetcher, options.method],
  );

  const swrResponse =
    /** @type {import("swr").SWRResponse<import("@raiden/library/types/Api/ApiResponse").ApiResponse<T, M>>} */ (
      useSWR(url, fetcher, {
        ...SWR_CONFIG_FOR_STATIC_DATA,
        ...options.swrConfig,
      })
    );

  return { swrResponse };
}
