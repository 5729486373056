import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

/**
 * @typedef {Object} Props
 * @property {string} src
 * @property {(params: { ref: import("react").MutableRefObject<HTMLImageElement | null>; src: string; isLoaded: boolean; handleLoad: () => void }) => React.ReactElement} children
 *
 * @param {Props} props
 */
function ImageLoaderHandler({ src, children }) {
  const [isLoaded, setIsLoaded] = useState(false);

  const refPreviousSrc = useRef(src);

  /** @type {import("react").MutableRefObject<HTMLImageElement | null>} */
  const ref = useRef(null);

  // reset loading state on src change
  useEffect(() => {
    if (refPreviousSrc.current !== src) {
      setIsLoaded(false);
      refPreviousSrc.current = src;
    }
  }, [src]);

  useLayoutEffect(() => {
    if (ref.current?.complete === true) {
      setIsLoaded(true);
    }
  }, []);

  const handleLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  return children({
    ref,
    src,
    isLoaded,
    handleLoad,
  });
}

export default ImageLoaderHandler;
