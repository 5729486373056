import { Image, Text, VStack } from "@chakra-ui/react";
import ImageLoaderHandler from "@raiden/library/components/ImageLoaderHandler";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {string} title
 * @property {string} [src]
 * @property {string} [description]
 * @property {React.ReactNode} [addon]
 *
 * @param {Props} props
 */
function Splash({ title, src, description, addon }) {
  const intl = useIntl();
  return (
    <VStack spacing="1rem">
      {src && (
        <ImageLoaderHandler src={src}>
          {({ ref, src, isLoaded, handleLoad }) => (
            <Image
              ref={ref}
              src={src}
              opacity={isLoaded ? 1 : 0}
              onLoad={handleLoad}
              transition="opacity .25s ease"
              alt={intl.formatMessage({ defaultMessage: "Splash" })}
            />
          )}
        </ImageLoaderHandler>
      )}

      <Text
        textAlign="center"
        fontSize="1.25rem"
        fontWeight={500}
        lineHeight="1.75rem">
        {title}
      </Text>

      {description && (
        <Text
          w="full"
          textAlign="center"
          fontSize="1rem"
          fontWeight={400}
          lineHeight="1.5rem"
          colorScheme="gray"
          whiteSpace="pre-wrap">
          {description}
        </Text>
      )}

      {addon && addon}
    </VStack>
  );
}

export default Splash;
